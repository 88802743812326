/* eslint-disable @typescript-eslint/camelcase */ 

import  { apiModel } from '../api.url'; 


 
const url =  apiModel.getApi( ) + '/seguranca'  ;
const urlRelatorio =  apiModel.getApi( ) + '/relatorio'  ;

const urlSite =  apiModel.getSiteApi( )  ;

const secret_key = 'fgsdf8sfg*bxdfbsd*bxfdsf2323sf**';

function getKeyCripto(  ) { 
	return  secret_key; 
}

function getUrl(  ) { 
	return  url; 
}

function getSiteApi(  ) { 
	return  urlSite; 
}

 

function getUrlRelatorio(  ) { 
	return  urlRelatorio; 
}

 
export const segurancaModel = { 
	getUrl, 
	getUrlRelatorio, 
	getSiteApi,
	getKeyCripto,
};