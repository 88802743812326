import Vue from 'vue'
import Vuex from 'vuex'
import Usuario from '@/store/modules/Usuario'  

Vue.use(Vuex)
 
const store = new Vuex.Store({ 
   modules: {  
      usuario:Usuario,  
   }
})
 
export default store; 