







































































 	import { Component, Vue } from 'vue-property-decorator';
	import { namespace } from 'vuex-class';
    import $ from "jquery" 
	
    const usuario = namespace('usuario')

    @Component({
        components: { 
        },
    })
    export default class Footer extends Vue {
		

		@usuario.State
		public status!: any

		@usuario.State
		public perfis!: any

		get loggingIn(): boolean {
			if (this.status) {
				return  this.status.loggedIn ;
			}
			return false;
		}


		get admin(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Admin") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}

		get gerente(): boolean {
			if (this.perfis) {
				return (
					this.perfis.filter( ( perfil: any) => {
						if (perfil == "Gerente") {
							return true;
						}
					}).length > 0
				);
			}
			return false;
		}


		public removeMenu() {  
			$('body').removeClass('control-sidebar-slide-open');  
			$("#aside-admin-id").css({ display: "none" }); 
        }  

	} 
 
