
import { RouteConfig } from 'vue-router' 
 
import TextoIndex from '@/views/v2/administrador/texto/Index.vue';
import TextoShow from '@/views/v2/administrador/texto/Show.vue'; 
import TextoEdit from '@/views/v2/administrador/texto/Edit.vue';
import TextoCadastro from '@/views/v2/administrador/texto/Cadastro.vue';
  
import ItemSaidaIndex from '@/views/v2/administrador/itemSaida/Index.vue'; 
import ItemSaidaEdit from '@/views/v2/administrador/itemSaida/Edit.vue';
import ItemSaidaCadastro from '@/views/v2/administrador/itemSaida/Cadastro.vue';
  
import SaidaIndex from '@/views/v2/administrador/saida/Index.vue'; 
import SaidaEdit from '@/views/v2/administrador/saida/Edit.vue';
import SaidaCadastro from '@/views/v2/administrador/saida/Cadastro.vue';
  
  
  
import EntradaIndex from '@/views/v2/administrador/entrada/Index.vue'; 
import EntradaEdit from '@/views/v2/administrador/entrada/Edit.vue';
import EntradaCadastro from '@/views/v2/administrador/entrada/Cadastro.vue';
  
  
import BarraLateralAdmin from '@/components/layout/BarraLateralAdmin.vue'
import Header from '@/components/layout/Header.vue' 
import Footer from '@/components/layout/Footer.vue'

const routesAdministrador: Array<RouteConfig> = [
      
	/*
	|--------------------------------------------------------------------------
	| Texto
	|--------------------------------------------------------------------------
	*/
	{
		path: '/texto', 
		components: {
			default: TextoIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin' , 'Gerente'] }
	},
	  
	{
		path: '/texto/edit/:id', 
		components: {
			default: TextoEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente'] }
	}, 
	{
		path: '/texto/show/:id', 
		components: {
			default: TextoShow,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente']}
	},  
	{
		path: '/texto/create', 
		components: {
			default: TextoCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente']}
	},   
	  
      
	/*
	|--------------------------------------------------------------------------
	| ItemSaida
	|--------------------------------------------------------------------------
	*/
	{
		path: '/item', 
		components: {
			default:ItemSaidaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin' , 'Gerente'] }
	},
	 
	{
		path: '/item/edit/:id', 
		components: {
			default: ItemSaidaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente'] }
	},  
	{
		path: '/item/create', 
		components: {
			default: ItemSaidaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente']}
	},   
	   

	/*
	|--------------------------------------------------------------------------
	|  Saida
	|--------------------------------------------------------------------------
	*/
	{
		path: '/saida', 
		components: {
			default:SaidaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin' , 'Gerente'] }
	},
	 
	{
		path: '/saida/edit/:id', 
		components: {
			default: SaidaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente'] }
	},  
	{
		path: '/saida/create', 
		components: {
			default: SaidaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente']}
	},   
	  



	/*
	|--------------------------------------------------------------------------
	|  Entrada
	|--------------------------------------------------------------------------
	*/
	{
		path: '/entrada', 
		components: {
			default:EntradaIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin' , 'Gerente'] }
	},
	 
	{
		path: '/entrada/edit/:id', 
		components: {
			default: EntradaEdit,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente'] }
	},  
	{
		path: '/entrada/create', 
		components: {
			default: EntradaCadastro,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		}, 
		meta: { requiresAuth: true  , requiresPerfil: ['Admin', 'Gerente']}
	},   
	  
]
 
export default routesAdministrador 