


















































	/* eslint-disable @typescript-eslint/camelcase */  
    import { itemService  } from '@/service'; 
    import { Component, Vue } from 'vue-property-decorator';  
	@Component({
		components: {   },
	})
    export default class Cadastro extends Vue { 
		private nome: any= null;  
		private saida: any= false;  
		private entrada: any= false;  
		private urlRetorno: any= '/item'; 
		private errors: any = { }; 
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,   
				saida :this.saida,   
				entrada :this.entrada   
			};   
			itemService.cadastrar( dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		}  
    } 
