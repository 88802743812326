



























    /* eslint-disable @typescript-eslint/camelcase */ 
    
    import PermissaoFormAdicionar from './_PermissaoFormAdicionar.vue'

    import { perfilService } from '@/service';
 
    import $ from "jquery"  

    import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
            'permissao-form-adicionar': PermissaoFormAdicionar,
        },
    })
    export default class PerfilPermissao extends Vue {

        private permissoes: any= null; 

        private perfil: any= null; 
        
        private reloadDatatable: any= false; 
         

		private url_retorno: any= '/perfil'; 
		private url_historico: any= '/perfil/' + this.$route.params.id + '/permissao/historico'; 
		 

		private config: any= {  
            order: [ [1, "asc"] ],
            ajax: {
                url: perfilService.getUrl() + '/' + this.$route.params.id + '/permissao/datatable'
            },
            columns: [
                {
                    data: 'id',
                    name: 'id'
                },
                {
                    data: 'nome',
                    name: 'nome'
                },
                {
                    data: 'descricao',
                    name: 'descricao'
                },
                {
                    data: 'action',
                    name: 'action',
                    orderable: false,
                    searchable: false,
                    class: 'text-center'
                }
            ],
		};  


        created() {
            window.alertProcessando();
            perfilService.getPerfil(this.$route.params.id)
                .then(response => {
                    window.alertProcessandoHide();
                    this.perfil = response; 
                })
                .catch(error => {
                    window.alertProcessandoHide();
                    window.toastErro('Não foi possivel achar a Perfil', error.data);
                    this.$router.push(this.url_retorno);
                }); 
        }

         

        permissaoAdicionada(event: any) {
            this.permissoes = event; 
            this.reloadDatatable = !this.reloadDatatable;
        }

        buscarPermissoes() {
            window.alertProcessando();
            perfilService.buscarPermissoes(this.$route.params.id)
                .then(response => {
                    this.permissoes = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Permissoes', error.data);
                    window.alertProcessandoHide();
                });

        }


        @Watch('perfil')
		perfilAlterado(newVal: any) {
			 this.buscarPermissoes();
		}



        public tableatualizada( ) {  

			 $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        perfilService.removerPermissao( this.$route.params.id,  id )
                        .then(response => {
							window.alertProcessandoHide();  
							this.permissoes = response;
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Permissao removido com sucesso!!' ); 
                        })
                        .catch(error => { 
							this.reloadDatatable = !this.reloadDatatable; 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel remover a Permissao' , ''); 
                        });  
                    }
                ); 
            });

        }


    }
 

