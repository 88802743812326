





































 
	
	import Form from '../../components/_core/formulario/Form'; 

	import {   profileService  }  from '@/service';
 
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class ProfileAlterarSenha extends Vue { 
 
		private password: any = null; 
		private passwordConfirm: any = null;  

		private errors: any = { }; 

		salvar() {
			window.alertProcessando();
			const dados = {
				password :this.password,
				passwordConfirm :this.passwordConfirm, 
			};   
			 
			profileService.alterarSenha(  dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push('/profile')
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		}  
	} 

