/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..';  
import { administradorModel } from './index.model';   
import axios  from 'axios'; 
    
const url = administradorModel.getUrl() + '/cadastro' ; 
  
function getUrl(  ) { 
	return  url; 
}
   
function recuperSenha( dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/reset/senha' , dados, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {   
			reject(error.response);
		}) 
	}); 
}
   
export const cadastroService = { 
	getUrl,
    recuperSenha, 
};