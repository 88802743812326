























	
	import { permissaoService  }  from '@/service';


	import $ from "jquery"  

    import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class PermissaoIndex extends Vue {

		private  reloadDatatable = false ; 

		private config = { 
            order: [[ 1, "asc" ]],
			ajax: { 
				url: permissaoService.getUrl() + '/datatable'
			},
			columns: [
				{ data: 'id', name: 'id'  },
				{ data: 'nome', name: 'nome' },
				{ data: 'descricao', name: 'descricao' }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};
		


		
		public tableatualizada( ) {   
            
            $('[btn-visualizar-perfis]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/permissao/' + id + '/perfis');
            });

            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/permissao/edit/' + id  );
            });


            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        permissaoService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

		}
		


	}

 

