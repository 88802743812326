























 
    /* eslint-disable @typescript-eslint/camelcase */  
    import {   entradaService } from '@/service'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
    import Cadastro from './Cadastro.vue'
      
    @Component({
        components: { Cadastro },
    })
    export default class Index  extends Vue { 

        private  reloadDatatable = false ; 
        private  token = '' ; 

        private config = {  
            exclusao: {
                url: entradaService.getUrl(),
                evento: 'entradaRemovida',
                item: 'entrada',
            },
            order: [  [3, "desc"] , [0, "desc"] ],
            ajax: {
                url: entradaService.getUrl() + '/datatable',
            },
            columns: [ 
                { data: 'id', name: 'entrada.id',  class: 'text-center' },
                { data: 'valor',  name: 'valor'  },  
                { data: 'forma_pagamento',  name: 'forma_pagamento'  },  
                { data: 'dia',  name: 'dia'  },  
                { data: 'item.nome',  name: 'item.nome'  },   
                { data: 'colaborador.nome',  name: 'colaborador.nome'  },   
                { data: 'comissao',  name: 'comissao'  },   
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ], 
            columnDefs : [  
                {
                    targets: 2,
                    render: function ( row, type, val, meta ) {  
                        if( row == 1   ){ return 'Dinheiro';  } 
                        if( row == 2   ){ return 'Crédito';  } 
                        if( row == 3    ){ return 'Débito';  } 
                        if( row == 4   ){ return 'Cheque';  } 
                        if( row == 5   ){ return 'N. Promissória ';  } 
                    } 
                },  
            ]
        };
  
        public entradaAdicionada(){
            this.reloadDatatable = !this.reloadDatatable; 
        }
 
        public tableatualizada( ) {   
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('entrada/edit/' + id  ); 
            }); 
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        entradaService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            }); 
        }
    }
 
