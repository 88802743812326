


































































































	/* eslint-disable @typescript-eslint/camelcase */  	
    import { itemService, entradaService , userService   } from '@/service';  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class Edit extends Vue {
		
		
		private valor: any= null;  
		private colaborador_id: any= null;  
		private item_id: any= null;   
		private forma_pagamento: any= 1 ;  
		private dia: any= new Date() ;  
		private parcelas: any= 1 ;   
		private porc_comissao: any= null ;  
		private comissao: any= null ;  
 
		private colaboradores: any= null;  
		private itens: any= null;  
 

        private model: any = null;  
		private errors: any = { };
  		private urlRetorno: any= '/entrada'; 


		
		calculaComissao( ) {
			if( this.porc_comissao != null &&  this.valor != null ){
				this.comissao = ( this.porc_comissao  * this.valor / 100 ).toFixed(2) ;  
			}
        }

        @Watch('porc_comissao')
		formaPagamentoAtualizada(newVal: any) {
			if( newVal > 100 ){
				this.porc_comissao = 100;
			}
			else if( newVal < 1 && newVal != '' ){
				this.porc_comissao = 1;
			}
			this.calculaComissao( );
        }
         
        @Watch('valor')
		valorAtualizada(newVal: any) {
			this.calculaComissao( );
        }
		

        @Watch('forma_pagamento')
		formaAtualizada(newVal: any) {
			this.parcelas = 1 ;  
        }


        created() { 
			this.getColaboradores() ;
            this.getItensSaida() ;
            window.alertProcessando();
            entradaService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o entrada ', error.data);
                    window.alertProcessandoHide();
                     
                }); 
        }
 
		getColaboradores() { 
            userService.getColaboradores()
                .then(response => {
                    this.colaboradores = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar colaboradores');
                }); 
        }

		getItensSaida() { 
            itemService.BuscarProdutos()
                .then(response => {
                    this.itens = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar produtos');
                }); 
        }

 
        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.valor = this.model.valor; 
			this.colaborador_id = this.model.colaborador_id; 
			this.item_id = this.model.item_id; 
			this.parcelas = this.model.parcelas; 
			this.comissao = this.model.comissao; 
			this.porc_comissao = this.model.porc_comissao; 
			this.forma_pagamento = this.model.forma_pagamento; 
			this.dia =  this.model.dia.substring( 0, 10) ; 
        }
         
		salvar() {
			window.alertProcessando();
			const dados = {
				valor :this.valor,  
				colaborador_id :this.colaborador_id,  
				item_id :this.item_id,  
				parcelas :this.parcelas,  
				comissao :this.comissao,  
				porc_comissao :this.porc_comissao,  
				forma_pagamento :this.forma_pagamento,  
				dia :this.dia,  
			};  
			entradaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
    }
 
