











































import $ from "jquery"  
import { historicoService  }  from '@/service';
import { Component, Vue } from 'vue-property-decorator';
  
@Component({
    components: { },
})
export default class AudioHistorico extends Vue { 

    private  reloadDatatable = false ; 
    private  modal_adicionar_id = 'modal-config-audio-acesso-dados' ; 
    private  dado = '' ; 
    private  model = '' ; 
    private  operacao = '' ; 

    private config = {
        order: [[ 0, "desc" ]],
        ajax: { 
            url: historicoService.getUrl() + '/datatable'
        },
        columns: [
            { data: 'id', name: 'id'  }, 
            { data: 'operacao', name: 'operacao'  },  
            { data: 'usuario_id', name: 'usuario_id'  },  
            { data: 'host', name: 'host'  },  
            { data: 'ip_v4', name: 'ip_v4'  },  
            { data: 'created_at', name: 'created_at'  },  
            { data: 'model', name: 'model'  },  
            { data: 'id_model', name: 'id_model'  },  
            { data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
        ],
    };

    public tableatualizada(   ) {   
        $('[btn-visualizar-dados]').click(  (btn) => {  
            const dados =  $(btn.currentTarget).data('dados'); 
            const model =  $(btn.currentTarget).data('model'); 
            const operacao =  $(btn.currentTarget).data('operacao'); 
            this.dado = dados;  
            this.model = model;  
            this.operacao = operacao;  
            $('#' + this.modal_adicionar_id).modal('show');  
        }); 
    }

}


