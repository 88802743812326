




















































 
	
	/* eslint-disable @typescript-eslint/camelcase */ 
 
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { userService  }  from '@/service'; 

	@Component({
		components: { 
        },
	 })
    export default class UsuarioEdit extends Vue {
		  
		private nome: any = null;
		private cpf: any= null;  
		private email: any= null; 
		private telefone: any= null; 
  
		private urlRetorno: any= null;
 
		private errors: any = { };

		created() {  
			this.urlRetorno =  '/usuario';  
		}
  
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,
				cpf :this.cpf,
				email :this.email, 
				telefone :this.telefone,  
			};  
			  
			userService.cadastrar( dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
	} 

