
















































 
	
	/* eslint-disable @typescript-eslint/camelcase */  
    
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	import { userService  }  from '@/service'; 

	@Component({
		components: {  },
	 })
    export default class UsuarioShow extends Vue {
		
        private model: any = null;
         
		private apiUrl: any= null;
		private urlRetorno: any= null;
		private urlEditar: any= null; 
 
		private errors: any = { };
  
		private userId: any= null;
  
		created() { 
			this.apiUrl =  userService.getUrl() + '/' + this.$route.params.id; 
            this.urlRetorno =  '/usuario'; 
            this.urlEditar =  '/usuario/edit/' + this.$route.params.id; 
             

			window.alertProcessando();
			userService.getUsuario( this.$route.params.id ) 
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o usuario', error.data);
				window.alertProcessandoHide();
			}); 
        } 
	} 

