
























	/* eslint-disable @typescript-eslint/camelcase */ 
 
	import {   profileService  }  from '@/service';
	import moment  from 'moment';
    import { namespace } from 'vuex-class'
    const usuario = namespace('usuario')
 
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class ProfileIndex extends Vue { 
          
		@usuario.State
	  	public user!: string
	    
		data_br(value: any): string {
			return moment(value).format("DD/MM/YYYY");
		}

	} 
