
























 
    /* eslint-disable @typescript-eslint/camelcase */  
    import {   itemService } from '@/service'; 
	import $ from "jquery"   
    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Index  extends Vue { 

        private  reloadDatatable = false ; 
        private  token = '' ; 

        private config = {  
            exclusao: {
                url: itemService.getUrl(),
                evento: 'itemRemovida',
                item: 'item',
            },
            order: [  [1, "asc"] ],
            ajax: {
                url: itemService.getUrl() + '/datatable',
            },
            columns: [
                { "class": "details-control text-success", "orderable": false, "data": null, "defaultContent": ""  },  
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'nome',  name: 'nome'  },  
                { data: 'saida',  name: 'saida'  },  
                { data: 'entrada',  name: 'entrada'  },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ], 
        };
  
         created() {
            const temp_token = localStorage.getItem('user');
            if(temp_token){
                this.token =  JSON.parse(temp_token)   ;
            } 
        }
 
        public tableatualizada( ) {   
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open('#/item/edit/' + id);  
            }); 
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        itemService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            }); 
        }
    }
 
