<template>
    <a href="#/" class="bg-white brand-link ">
        <img src="/img/logo.jpg" :alt="logo" class="brand-image img-circle"> 
        <span class="brand-text font-weight-light"> 
            <b>{{logo}}</b>
        </span>
    </a>  
</template>

<script>
    export default {
        props: {  
            logo: {
                type: String, 
                default: 'Salão'
            }, 
        },

    }

</script>

<style scoped>
.brand-link {
    padding-left: 1px;
    padding-right: 1px;
    font-size: 1.10rem;
}

.brand-image{
    margin-right: 6px;
    margin-left: 9px;
}
b{
    font-weight: 600;
}
</style>
