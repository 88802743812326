




































































































































































	/* eslint-disable @typescript-eslint/camelcase */  
    import {itemService, entradaService , userService  } from '@/service'; 
    import {Watch,  Component, Vue } from 'vue-property-decorator';  
	import moment from 'moment';  

	@Component({
		components: {   },
	})
    export default class Cadastro extends Vue { 

		private valor: any= null;  
		private colaborador_id: any= null;  
		private item_id: any= null;   
		private forma_pagamento: any= 1 ;  
		private dia: any= new Date() ;  
		private parcelas: any= 1 ;  

		private porc_comissao: any= null ;  
		private comissao: any= null ;  


		private colaboradores: any= null;  
		private itens: any= null;  

		private urlRetorno: any= '/entrada'; 
		private errors: any = { }; 


		calculaComissao( ) {
			if( this.porc_comissao != null &&  this.valor != null ){
				this.comissao = ( this.porc_comissao  * this.valor / 100 ).toFixed(2) ;  
			}
        }

        @Watch('porc_comissao')
		formaPagamentoAtualizada(newVal: any) {
			if( newVal > 100 ){
				this.porc_comissao = 100;
			}
			else if( newVal < 1 && newVal != '' ){
				this.porc_comissao = 1;
			}
			this.calculaComissao( );
        }
         
        @Watch('valor')
		valorAtualizada(newVal: any) {
			this.calculaComissao( );
        }
		

        @Watch('forma_pagamento')
		tabelaAtualizada(newVal: any) {
			this.parcelas = 1 ;  
        }

        created() { 
            this.getColaboradores() ;
            this.getItensSaida() ;
			this.dia = moment(this.dia).format('YYYY-MM-DD')
        }

		getColaboradores() { 
            userService.getColaboradores()
                .then(response => {
                    this.colaboradores = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar colaboradores');
                }); 
        }

		getItensSaida() { 
            itemService.BuscarProdutos()
                .then(response => {
                    this.itens = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar produtos');
                }); 
        }
		
		salvarProduto() {
			window.alertProcessando();
			const dados = {
				valor :this.valor , 
				item_id :this.item_id ,   
				forma_pagamento :this.forma_pagamento ,  
				dia :this.dia ,  
				parcelas :this.parcelas ,  
			};   
			this.salvar( dados );
		}  

		salvarServico() {
			window.alertProcessando();
			const dados = {
				valor :this.valor ,  
				colaborador_id :this.colaborador_id ,   
				forma_pagamento :this.forma_pagamento ,  
				dia :this.dia ,
				parcelas :this.parcelas ,    
				porc_comissao :this.porc_comissao ,    
				comissao :this.comissao ,    
			};   
			this.salvar( dados );
		}  


		salvar(dados) { 
			entradaService.cadastrar( dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$emit('entradaAdicionada', ''); 
				this.valor = null;
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		}  

    } 
