
















































	/* eslint-disable @typescript-eslint/camelcase */  	
    import { itemService } from '@/service';  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class Edit extends Vue {

        private model: any = null; 

		private nome: any= null;  
		private saida: any= false;  
		private entrada: any= false;  

		private errors: any = { };
  		private urlRetorno: any= '/item'; 

        created() { 
            window.alertProcessando();
            itemService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o item ', error.data);
                    window.alertProcessandoHide();
                     
                }); 
        }
 
        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome; 
			this.saida = this.model.saida; 
			this.entrada = this.model.entrada; 
        }
         
		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome,  
				saida :this.saida,  
				entrada :this.entrada,  
			};  
			itemService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
    }
 
