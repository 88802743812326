
































    /* eslint-disable @typescript-eslint/camelcase */ 
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { userService  }  from '@/service'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormUsuarioPerfil extends Vue {

        @Prop( ) readonly perfis!: any

        private perfil: any = null;

        private add_perfil_id = 'modal-seguranca-usuario-adicionar-perfil'; 

         AdicionarPerfil() {
            if (this.perfil) {

                window.alertProcessando();
                const dados = {
                    perfil :this.perfil, 
                };   

                userService.adicionarPerfil( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("Perfil adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('perfilAdicionado', response);
                    ( $('#' + this.add_perfil_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });  

                 
            }
        }
    }



     

