/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..'; 
import { segurancaModel } from './index.model'; 
  
import axios  from 'axios';  
 
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js';

const secret_key =  segurancaModel.getKeyCripto() ;
 

const url = segurancaModel.getUrl() + '/usuario' ; 
const urlRelatorio = segurancaModel.getUrlRelatorio() + '/usuario' ;  
const urlLogin = segurancaModel.getUrl() + '/auth' ;  
 

import  store   from '@/store/index' 

function logout2() {  
	store.dispatch('usuario/logout'); 
}

function getUrlRelatorio(  ) { 
	return  urlRelatorio; 
}

function getUrl(  ) { 
	return  url; 
}
  
function resetarSenha( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( url + '/resetarSenha/' + id , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function AlterarColaborador( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + '/colaborador', {} , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

 
 

function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}

function adicionarPerfil( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/' + id  + "/adicionar/perfil" , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}



function removerPerfil( id: any , perfil: any) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id + '/delete/perfil/' + perfil ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function getUsuario( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
 
 
function buscarPerfis( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + "/perfil/adicionar" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout2();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function logout() { 
	localStorage.removeItem('user');
	localStorage.clear( ); 
}


function login(id: any, password: any) { 
	const data: any = {};  
	data['cpf'] = id ;  
	data['password'] = password ;  
	return  new Promise((resolve, reject) => {
		axios.post( urlLogin + '/login' , data )
		.then(response => {   
			localStorage.setItem('user', JSON.stringify(response.data.access_token));
			resolve( response.data.access_token);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response.data.error);
		}) 
	}); 
}


function cadastrar(data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url   , data , {headers: authHeader() } )
		.then(response => {   
			resolve( response.data );  
		})
		.catch(error => {  
			reject(error.response.data);
		}) 
	}); 
}
 

function getColaboradores( api = false ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('usuario_global_colaboradores' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('usuario_data_global_colaboradores'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/buscar/colaboradores' , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('usuario_global_colaboradores' , encripted_conceitos  );    
			localStorage.setItem('usuario_data_global_colaboradores'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}

 


export const userService = {
	cadastrar,
	login,
	logout,
	getUrl,
	getUrlRelatorio,
	getUsuario,
	buscarPerfis, 
	AlterarColaborador, 
	resetarSenha, 
	excluir,
	update, 
	adicionarPerfil,
	removerPerfil, 
	getColaboradores,
};

