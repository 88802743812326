




























    
    import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { perfilService  }  from '@/service'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormPerfilPermissao extends Vue {

        @Prop( ) readonly permissoes!: any

        private permissao: any = null;

        private add_permissao_id = 'modal-seguranca-perfil-adicionar-permissao'; 


        AdicionarPermissao() {
            if (this.permissao) {

                window.alertProcessando();
                const dados = {
                    permissao :this.permissao, 
                };   

                perfilService.adicionarPermissao( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("permissao adicionado com successo.");
                    window.alertProcessandoHide();
                    this.$emit('permissaoAdicionada', response);
                    ( $('#' + this.add_permissao_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }

 
