import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router' 
import Principal from '../views/Principal.vue'
import Login from '@/views/login/Login.vue';
import EsqueciSenha from '@/views/login/EsqueciSenha.vue';
 
import * as KJUR from "jsrsasign" ; 
    
import routesSeguranca from './seguranca';
import routesAdministrador from './administrador';
 
import Header from '@/components/layout/Header.vue'  
import Footer from '@/components/layout/Footer.vue'
 
import ProfileIndex from '@/views/profile/Index.vue';
import ProfileAlterarSenha from '@/views/profile/alterarSenha.vue';
    
import BarraLateralAdmin from '@/components/layout/BarraLateralAdmin.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	
	{
		path: '/',
		name: 'Home',
		components: {
			default: Principal,
			sidebar: BarraLateralAdmin,
			header: Header, 
			footer: Footer,
		}, 
	},
 

  	{
		path: '/login', 
		components: { 
			header: Login, 
		},
  	},

  	{
		path: '/recuperar_senha', 
		components: { 
			header: EsqueciSenha, 
		},
  	},
     
	{
		path: '/profile', 
		components: {
			default: ProfileIndex,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},
  
	{
		path: '/alterar/senha',  
		components: {
			default: ProfileAlterarSenha,
			sidebar: BarraLateralAdmin,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},
 
];
  
routesSeguranca.forEach(element => {
	routes.push(element);
});
 
routesAdministrador.forEach(element => {
	routes.push(element);
});
 
routes.push({ path: '*', redirect: '/' });
 
const router = new VueRouter({
  routes
})
 
router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		const user = localStorage.getItem('user');
		if (!user) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath,
				},
			});
		}
		else if (to.matched.some(record => record.meta.requiresPerfil)) { 
			const userObject = JSON.parse(user); 
			const perfis = KJUR.jws.JWS.parse(userObject).payloadObj.perfis; 
		 
			if (perfis.filter(
				( perfil: any ) => {
					let temp = false 
					to.meta.requiresPerfil.every( (element:  any ) => { 
						if (perfil == element) { 
							temp = true; 
							return false;
						}
						return true
					});

					return temp;

				}).length > 0) { 
				next();
			}
			else { 
				next({
					path: '/',
					query: {
					},
				});
			}
		}
		else {
			next();
		} 

	} else {
		next();
	}
})
 
export default router
