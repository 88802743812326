

















	/* eslint-disable @typescript-eslint/camelcase */ 

	import { perfilService  }  from '@/service';

	 import {Watch,  Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: { 
        },
    })
    export default class PerfilUsuarios extends Vue {


        private perfil: any = null; 
        private url_retorno: any = '/perfil'; 

		private config: any= { 
			order: [[ 1, "asc" ]],
			ajax: { 
				url: perfilService.getUrl() + '/' + this.$route.params.id + '/usuarios/datatable'
			},
			columns: [
				{ data: 'usuario_id', name: 'id'  }, 
				{ data: 'nome', name: 'nome' }, 
			],
		}

		created() {
			window.alertProcessando();
			perfilService.getPerfil( this.$route.params.id) 
			.then(response => {
				this.perfil = response ;  
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.alertProcessandoHide();
				window.toastErro('Não foi possivel achar a Perfil' , error.data);
				this.$router.push('/perfil'); 
			});   
 
		}


	}

 
