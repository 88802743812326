


















    import { permissaoService } from '@/service';


    import { Component, Vue } from 'vue-property-decorator';
     
    @Component({
        components: {
             
        },
    })
    export default class PermissaoPerfis extends Vue {

		private permissao: any = null;
		private urlRetorno: any = '/permissao';


        private config = { 
            order: [  [1, "asc"]  ],
            ajax: {
                url: permissaoService.getUrl() + '/' + this.$route.params.id + '/perfis/datatable'
            },
            columns: [
                { data: 'id', name: 'id'  },
                { data: 'nome', name: 'nome' },
                { data: 'descricao', name: 'descricao' },
            ],
        };
        
        created() {
            window.alertProcessando();
            permissaoService.getPermissao(this.$route.params.id)
                .then(response => {
                    this.permissao = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Permissão', error.data);
                    window.alertProcessandoHide();
                    this.$router.push(this.urlRetorno);
                }); 
        }


    }
     

