








































































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import { textoService } from '@/service';

    import Editor from '@tinymce/tinymce-vue';

    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class PlanoCadastro extends Vue {

        private model: any = null;
 

		private nome: any= null; 
        private descricao: any= null;
        
 
		private urlRetorno: any= '/texto/show/' + this.$route.params.id;

		private errors: any = { };
 
 
        created() { 
            window.alertProcessando();
            textoService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o texto ', error.data);
                    window.alertProcessandoHide();
                     
                }); 
        }


        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.nome = this.model.nome;
			this.descricao = this.model.descricao;   
        }
        


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome, 
				descricao :this.descricao,  
			};  
			 
			 
			textoService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){  
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){  
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }



 

