 
// const  url =  'http://127.0.0.1:8001/api/v1' ; 
// const  urlSite =  'http://127.0.0.1:8001' ; 
  

const  url =  'https://salao-api.guiaonlinecursos.com.br/api/v1' ; 
const  urlSite =  'https://salao-api.guiaonlinecursos.com.br' ; 
 

const  apiMap =  'AIzaSyCxGKoF5sKhGhQCxD3h-i0zdbd_mQwyvhc' ; 
 
 
function getApi(  ) { 
	return  url; 
}
 
function getSiteApi(  ) { 
	return  urlSite; 
}
 
function getApiMap(  ) { 
	return  apiMap; 
}
 
export const apiModel = { 
	getApi,  
	getApiMap, 
	getSiteApi, 
};
 