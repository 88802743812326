










































































































































	/* eslint-disable @typescript-eslint/camelcase */  
    import {itemService, saidaService , userService  } from '@/service'; 
    import { Component, Vue } from 'vue-property-decorator';  
	import moment from 'moment';  

	@Component({
		components: {   },
	})
    export default class Cadastro extends Vue { 

		private valor: any= null;  
		private colaborador_id: any= null;  
		private item_id: any= null;  
		private colaborador_tipo: any= null;  
		private forma_pagamento: any= 1 ;  
		private dia: any= new Date() ;  


		private colaboradores: any= null;  
		private itens: any= null;  

		private urlRetorno: any= '/saida'; 
		private errors: any = { }; 

		
        created() { 
            this.getColaboradores() ;
            this.getItensSaida() ;
			this.dia = moment(this.dia).format('YYYY-MM-DD')
        }

		getColaboradores() { 
            userService.getColaboradores()
                .then(response => {
                    this.colaboradores = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar colaboradores');
                }); 
        }

		getItensSaida() { 
            itemService.BuscarDespesas()
                .then(response => {
                    this.itens = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar despesas');
                }); 
        }

		salvarSalario() {
			window.alertProcessando();
			const dados = {
				valor :this.valor ,  
				colaborador_id :this.colaborador_id ,  
				colaborador_tipo :this.colaborador_tipo ,  
				forma_pagamento :this.forma_pagamento ,  
				dia :this.dia ,  
			};   
			this.salvar( dados ); 
		}  

		salvarDespesas() {
			window.alertProcessando();
			const dados = {
				valor :this.valor ,   
				item_id :this.item_id ,  
				forma_pagamento :this.forma_pagamento ,  
				dia :this.dia ,  
			};   
			this.salvar( dados ); 
		}  

		salvar( dados ) {
			window.alertProcessando(); 
			saidaService.cadastrar( dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$emit('saidaAdicionada', ''); 
				this.valor = null;
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		}  

    } 
