/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..'; 
import axios  from 'axios';  
import { administradorModel } from './index.model';  
import  store   from '../../store/index'  


import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js';

const secret_key =  administradorModel.getKeyCripto() ;

const url = administradorModel.getUrl() + '/item' ;  


function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}


function getAll( api = false ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('usuario_global_items' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('usuario_data_global_items'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/all' , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('usuario_global_items' , encripted_conceitos  );    
			localStorage.setItem('usuario_data_global_items'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}
 
 
function getModel( id: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function cadastrar(   dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function update( id: any , dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.patch( url + '/' + id   , dados , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function excluir( id: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.delete( url + '/' + id ,  {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}



function BuscarProdutos( api = false ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('usuario_global_produtos' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('usuario_data_global_produtos'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/produtos' , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('usuario_global_produtos' , encripted_conceitos  );    
			localStorage.setItem('usuario_data_global_produtos'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}
 



function BuscarDespesas( api = false ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('usuario_global_despesas' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('usuario_data_global_despesas'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/despesas' , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('usuario_global_despesas' , encripted_conceitos  );    
			localStorage.setItem('usuario_data_global_despesas'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}
 
 

export const itemService = {
	getModel , 
	getUrl,
	getAll , 
	update,
	cadastrar, 
	excluir,
	BuscarProdutos,
	BuscarDespesas,
};