


























import $ from "jquery"  

import { Component, Vue } from 'vue-property-decorator';

import { userService } from '@/service'; 


@Component({
    components: {
         
    },
})
export default class UsuarioIndex extends Vue {
    
    private config = { 
        responsive: {
            details: false
        },
        order: [[4, "desc"]],
        ajax: {
            url: userService.getUrl() + "/datatable",
        },
        columns: [
            { data: "id",  name: "id", },
            { data: "nome",  name: "nome"  },
            { data: "email",  name: "email" , class:'desktop' },
            { data: "colaborador", name: "colaborador"  }, 
            {  data: "created_at", name: "created_at" , class:'desktop', }, 
            { data: "action", name: "action",  orderable: false, searchable: false, class: "text-center", },
        ],
        columnDefs : [  
            {
                targets: 3,
                render: function ( row, type, val, meta ) {  
                    if( row   ){ return '<button  class="btn btn-success btn-sm">Sim</button>';  }
                    return '<button  class="btn btn-danger btn-sm">Não</button>'; 
                } 
            }, 
        ]
    };

    private  reloadDatatable = false ; 

        
    public tableatualizada( ) {  
         
        $('[btn-editar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/usuario/edit/' + id);
        });

        $('[btn-visualizar]').click(  (btn) => {  
            const id =   $(btn.currentTarget).data('id'); 
            this.$router.push('/usuario/show/' + id);
        }); 
        $('[btn-visualizar-perfil]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            this.$router.push('/usuario/' + id + '/perfil');
        }); 

        $('[btn-resetar-senha]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            window.alertConfimacao('Confirma o Reset de Senha ','' , 
                 () => {  
                    window.alertProcessando(); 
                    userService.resetarSenha(  id )
                    .then(response => {
                        window.alertProcessandoHide();  
                        window.toastSucesso('Reset realizada com sucesso!!' ); 
                    })
                    .catch(error => { 
                        window.alertProcessandoHide();
                        window.toastErro('Não foi possivel Resetar ' , error.data.message); 
                    });  
                }
            ); 
        });  

        $('[btn-colaborar]').click(  (btn) =>{  
            const id =   $(btn.currentTarget).data('id');  
            window.alertProcessando(); 
            userService.AlterarColaborador(  id )
            .then(response => {
                window.alertProcessandoHide();  
                this.reloadDatatable = !this.reloadDatatable ;
                window.toastSucesso('Reset realizada com sucesso!!' ); 
            })
            .catch(error => { 
                window.alertProcessandoHide();
                window.toastErro('Não foi possivel Resetar ' , error.data.message); 
            });  
        });  

    } 
} 

