

















































































	/* eslint-disable @typescript-eslint/camelcase */  	
    import { itemService, saidaService , userService   } from '@/service';  
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: {  },
	 })
    export default class Edit extends Vue {
		
		
		private valor: any= null;  
		private colaborador_id: any= null;  
		private item_id: any= null;  
		private colaborador_tipo: any= null;  
		private forma_pagamento: any= 1 ;  
		private dia: any= new Date() ;  
 
		private colaboradores: any= null;  
		private itens: any= null;  
 

        private model: any = null;  
		private errors: any = { };
  		private urlRetorno: any= '/saida'; 

        created() { 
			this.getColaboradores() ;
            this.getItensSaida() ;
            window.alertProcessando();
            saidaService.getModel(this.$route.params.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar o saida ', error.data);
                    window.alertProcessandoHide();
                     
                }); 
        }
 
		getColaboradores() { 
            userService.getColaboradores()
                .then(response => {
                    this.colaboradores = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar colaboradores');
                }); 
        }

		getItensSaida() { 
            itemService.BuscarDespesas()
                .then(response => {
                    this.itens = response;
                })
                .catch( () => {
                    window.toastErro('Não foi possivel achar despesas');
                }); 
        }

 
        @Watch('model')
		tabelaAtualizada(newVal: any) {
			this.valor = this.model.valor; 
			this.colaborador_id = this.model.colaborador_id; 
			this.item_id = this.model.item_id; 
			this.colaborador_tipo = this.model.colaborador_tipo; 
			this.forma_pagamento = this.model.forma_pagamento; 
			this.dia =  this.model.dia.substring( 0, 10) ; 
        }
         
		salvar() {
			window.alertProcessando();
			const dados = {
				valor :this.valor,  
				colaborador_id :this.colaborador_id,  
				item_id :this.item_id,  
				colaborador_tipo :this.colaborador_tipo,  
				forma_pagamento :this.forma_pagamento,  
				dia :this.dia,  
			};  
			saidaService.update( this.$route.params.id ,   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 
 
    }
 
